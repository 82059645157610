import setGuestEmailOnCartMutation, {
  MagentoSetGuestEmailOnCartResponse,
} from 'requests/graphql/mutations/setGuestEmailOnCartMutation'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  email: string,
  cartId: string
): Promise<MagentoSetGuestEmailOnCartResponse> => {
  const response = await axiosRequest<MagentoSetGuestEmailOnCartResponse>(
    setGuestEmailOnCartMutation({ cartId, email })
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed setting email on cart')
  }
  return response
}
