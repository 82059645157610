import cartQuery, {
  MagentoCartResponse,
} from 'requests/graphql/queries/cartQuery'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (cartId: string): Promise<MagentoCartResponse> => {
  const response = await axiosRequest<MagentoCartResponse>(
    cartQuery({ cartId })
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed fetching cart')
  }
  return response
}
