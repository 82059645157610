import isEmailAvailable, {
  MagentoIsEmailAvailableResponse,
} from 'requests/graphql/queries/isEmailAvailableQuery'

import { axiosRequest } from 'requests/axios/axiosRequests'

export default async (
  email: string
): Promise<MagentoIsEmailAvailableResponse> => {
  const response = await axiosRequest<MagentoIsEmailAvailableResponse>(
    isEmailAvailable({ email })
  )
  return response
}
