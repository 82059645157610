import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (
  variables: AddMpGiftCardProductsToCartVariables
): GraphqlQuery => {
  const { sku, sum, email, message, cartId, isCustomSum } = {
    ...variables,
  }
  return {
    query: `
      mutation addMpGiftCardProductsToCart($sku: String!, $sum: String!, $cartId: String!, $email: String!, $message: String!, $isCustomSum: Boolean!) {
        addMpGiftCardProductsToCart(
          input: {
            cart_id: $cartId
            cart_items: [
              {
                data: { quantity: 1, sku: $sku }
                giftcard_options: {
                  amount: $sum
                  range_amount: $isCustomSum
                  delivery: 1
                  email: $email
                  from: "Admin"
                  message: $message
                }
              }
            ]
          }
        ) {
          cart {
            ${cartFragment}
          }
        }
      }`,
    variables: {
      sku,
      sum,
      email,
      message,
      cartId,
      isCustomSum,
    },
  }
}

export type AddMpGiftCardProductsToCartVariables = {
  sku: string
  sum: number
  email: string
  message: string
  cartId: string
  isCustomSum: boolean
}

export type MagentoAddGiftcardProductToCartResponse = MagentoErrorResponse & {
  data?: {
    addMpGiftCardProductsToCart?: {
      cart: MagentoCart
    }
  }
}
