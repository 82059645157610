import { MagentoCustomerAddress } from '../fragments/customerAddressFragment'
import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: CreateCustomerAddressVariables): GraphqlQuery => {
  const {
    telephone,
    street,
    postcode,
    city,
    country_code,
    firstname,
    lastname,
  } = variables

  return {
    query: `
		mutation createCustomerAddress(
			$firstname: String!,
			$lastname: String!,
			$telephone: String!,
			$street: [String]!,
      $postcode: String!,
      $city: String!,
      $country_code: CountryCodeEnum!,
		) {
			createCustomerAddress(input: {
				firstname: $firstname 
				lastname: $lastname
				telephone: $telephone
				street: $street
				postcode: $postcode
				city: $city
				country_code: $country_code
			}) {
				id
				telephone
				street
				postcode
				city
				country_code
			}
		}
		`,
    variables: {
      firstname,
      lastname,
      telephone,
      street,
      postcode,
      city,
      country_code,
    },
  }
}

export type CreateCustomerAddressVariables = MagentoErrorResponse & {
  firstname: string
  lastname: string
  telephone: string
  street: string[]
  postcode: string
  city: string
  country_code: string
}

export type MagentoCreateCustomerAddressResponse = MagentoErrorResponse & {
  data?: {
    createCustomerAddress: MagentoCustomerAddress
  }
}
