import React from 'react'
import styled from 'styled-components'

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  return <ScreenReaderOnly>{children}</ScreenReaderOnly>
}

/*
Excerpt From Inclusive Components
Heydon Pickering
*/
const ScreenReaderOnly = styled.div`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
`
