import { MagentoCustomerAddress } from '../fragments/customerAddressFragment'
import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: DeleteCustomerAddressVariables): GraphqlQuery => {
  const { id } = variables

  return {
    query: `
		mutation deleteCustomerAddress(
			$id: Int!,
		) {
			deleteCustomerAddress(id: $id) 
		}
		`,
    variables: {
      id,
    },
  }
}

export type DeleteCustomerAddressVariables = MagentoErrorResponse & {
  id: number
}

export type MagentoDeleteCustomerAddressResponse = MagentoErrorResponse & {
  data?: {
    deleteCustomerAddress: MagentoCustomerAddress
  }
}
