import moneyFragment, { MagentoMoney } from './moneyFragment'

const cartPricesFragment = `
	grand_total {
		${moneyFragment}
	}
`
export default cartPricesFragment

export type MagentoCartPrices = {
  grand_total: MagentoMoney
}
