import * as customerService from 'requests/customerRequests'
import * as loginService from 'requests/loginRequests'

import React, { createContext, useReducer } from 'react'
import loginReducer, { initialLoginState } from '../reducers/loginReducer'

import { MagentoCrateCustomerResponse } from 'requests/graphql/mutations/createCustomerV2Mutation'
import { NewCustomerData } from 'requests/magento_customer/createCustomer'
import { ResetPasswordResponse } from 'requests/magento_login/resetPassword'
import { changeCustomerPassword } from 'requests/loginRequests'

// Initialize the context with an empty object that respects the types
export const LoginContext = createContext<LoginProvider>({
  changeLoggedInCustomerPassword: async () => Promise.resolve(false),
  changeLoggedOutCustomerPassword: () =>
    Promise.resolve({ success: false, message: '' }),
  createNewCustomer: async () => Promise.resolve({}),
  isLoggedIn: initialLoginState.isLoggedIn,
  customerToken: initialLoginState.customerToken,
  loginCustomer: async () => Promise.resolve(''),
  logoutCustomer: () => {
    return
  },
  setLoginState: () => {
    return
  },
})

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  const [state, dispatch] = useReducer(loginReducer, initialLoginState)

  /**
   *
   * @param currentPassword
   * @param newPassword
   * @param customerToken
   * @returns customer email if successful, otherwise false
   */
  const changeLoggedInCustomerPassword = async (
    currentPassword: string,
    newPassword: string,
    customerToken: string
  ): Promise<string | boolean> => {
    const response = await changeCustomerPassword(
      { currentPassword, newPassword },
      customerToken
    )
    if (!response.data) {
      return false
    }
    return response.data.changeCustomerPassword.email
  }

  const changeLoggedOutCustomerPassword = async (
    email: string,
    newPassword: string,
    resetPasswordToken: string
  ): Promise<ResetPasswordResponse> => {
    const response = await loginService.resetPassword({
      email,
      newPassword,
      resetPasswordToken,
    })
    return response
  }

  const createNewCustomer = async (
    newCustomerData: NewCustomerData
  ): Promise<MagentoCrateCustomerResponse> => {
    const response = await customerService.createCustomer(newCustomerData)
    return response
  }

  // Login to Magento, sets login state and customerToken to state
  const loginCustomer = async (
    password: string,
    email: string
  ): Promise<string> => {
    const response = await loginService.loginCustomer({
      password,
      email,
    })
    const customerToken = response.data?.generateCustomerToken.token
    if (!customerToken) {
      throw new Error('Did not receive customerToken from Magento')
    }
    dispatch({ type: 'LOGIN' })
    dispatch({ type: 'SET_CUSTOMER_TOKEN', payload: customerToken })
    return customerToken
  }

  const logoutCustomer = async (customerToken: string): Promise<void> => {
    await loginService.logoutCustomer(customerToken)
    dispatch({ type: 'SET_CUSTOMER_TOKEN', payload: null })
    dispatch({ type: 'LOGOUT' })
  }
  // Sets login state only in the application
  const setLoginState = async (
    isLoggedIn: boolean,
    customerToken?: string | null
  ) => {
    if (isLoggedIn && customerToken) {
      dispatch({ type: 'SET_CUSTOMER_TOKEN', payload: customerToken })
      dispatch({ type: 'LOGIN' })
    } else if (!isLoggedIn && customerToken) {
      dispatch({ type: 'LOGOUT' })
      dispatch({ type: 'SET_CUSTOMER_TOKEN', payload: null })
      await loginService.logoutCustomer(customerToken)
    } else {
      dispatch({ type: 'LOGOUT' })
      dispatch({ type: 'SET_CUSTOMER_TOKEN', payload: null })
    }
  }

  const value: LoginProvider = {
    changeLoggedInCustomerPassword,
    changeLoggedOutCustomerPassword,
    createNewCustomer,
    customerToken: state.customerToken,
    isLoggedIn: state.isLoggedIn,
    loginCustomer,
    logoutCustomer,
    setLoginState,
  }

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}

type LoginProvider = {
  changeLoggedInCustomerPassword: (
    currentPassword: string,
    newPasword: string,
    customerToken: string
  ) => Promise<string | boolean>
  changeLoggedOutCustomerPassword: (
    email: string,
    newPassword: string,
    resetPasswordToken: string
  ) => Promise<ResetPasswordResponse>
  createNewCustomer: (
    newCustomerData: NewCustomerData
  ) => Promise<MagentoCrateCustomerResponse>
  isLoggedIn: boolean | undefined
  customerToken?: string | null
  loginCustomer: (password: string, email: string) => Promise<string>
  logoutCustomer: (customerToken: string) => void
  setLoginState: (state: boolean, customerToken?: string | null) => void
}
