export const printMagentoResponseErrors = (
  errorResponse: MagentoErrorResponse
): void => {
  errorResponse.errors?.forEach(error =>
    console.error(`Magento response error message: ${error.message}`)
  )
}

export type MagentoErrorResponse = {
  errors?: [
    {
      message: string
    }
  ]
}

export const unexpectedErrorDefaultMessage =
  'Tapahtui odottamaton virhe. Ole hyvä ja yritä hetken kuluttua uudelleen. Ota yhteys asiakaspalveluumme jos virhe jatkuu.'
