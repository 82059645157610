import React from 'react'
import { capitalizeFirstLetter } from 'utils/forms'
import styleTokens from 'styleTokens'
import styled from 'styled-components'

type Props = {
  path: string
}
export const BreadCrumbs = ({ path }: Props) => {
  const crumbs = path.split('/').filter(crumb => crumb !== '')
  return (
    <Bread>
      {crumbs.length > 0 && (
        <li>
          <a href="/">Etusivu</a>
        </li>
      )}
      {crumbs.map(
        (crumb, i) =>
          crumb && (
            <li key={i}>
              <a href={`/${crumb}`}>
                <span>&gt;</span>
                {capitalizeFirstLetter(crumbText(crumb))}
              </a>
            </li>
          )
      )}
    </Bread>
  )
}

const crumbText = (crumb: string): string => {
  switch (crumb) {
    case 'cart':
      return 'ostoskori'
    case 'login':
      return 'kirjaudu sisään'
    case 'create':
      return 'luo uusi tili'
    case 'profile':
      return 'profiili'
    case 'order-success':
      return 'tilausvahvistus'
    case 'password':
      return 'salasana'
    case 'new-password':
      return 'uusi salasana'
    default:
      return crumb
  }
}

const Bread = styled.ul`
  padding-inline: var(--content-container-inline-padding);
  margin-block: var(--content-container-inline-padding);
  font-size: 12px;
  font-weight: 400;
  li {
    display: inline-block;
    a {
      span {
        margin-inline: 16px;
      }
    }
  }

  @media (min-width: ${styleTokens['content-container-max-width']}) {
    padding-inline: 0px;
  }
`
