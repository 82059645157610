import deleteCustomerAddress, {
  DeleteCustomerAddressVariables,
  MagentoDeleteCustomerAddressResponse,
} from 'requests/graphql/mutations/deleteCustomerAddressMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerData: DeleteCustomerAddressVariables,
  customerToken: string
): Promise<MagentoDeleteCustomerAddressResponse> => {
  const response = await axiosRequest<MagentoDeleteCustomerAddressResponse>(
    deleteCustomerAddress(customerData),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed updating customer address')
  }
  return response
}
