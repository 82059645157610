import moneyFragment, { MagentoMoney } from './moneyFragment'
import orderAddressFragment, {
  MagentoOrderAddress,
} from './orderAddressFragment'

const customerOrdersFragment = `
	items {
		id
		number
		order_date
		items {
			product_name
			product_sku
			product_url_key
			product_sale_price {
				${moneyFragment}
			}
			quantity_ordered
		}
		status
		total {
			grand_total {
				${moneyFragment}
			}
		}
		billing_address {
			${orderAddressFragment}
		}
	}
	total_count
`

export default customerOrdersFragment

export type MagentoCustomerOrdersList = {
  items?: MagentoCustomerOrder[] | []
  total_count?: number
}

export type MagentoCustomerOrder = {
  id: string
  number: number
  order_date: string
  items: MagentoCustomerOrderItem[]
  status: string
  total: {
    grand_total: MagentoMoney
  }
  billing_address: MagentoOrderAddress
}

export type MagentoCustomerOrderItem = {
  product_name: string
  product_sku: string
  product_url_key: string
  product_sale_price: MagentoMoney
  quantity_ordered: number
}
