import resetPassword, {
  MagentoResetPasswordResponse,
} from 'requests/graphql/mutations/resetPasswordMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

export default async (
  data: ResetPasswordData
): Promise<ResetPasswordResponse> => {
  const variables = {
    email: data.email,
    newPassword: data.newPassword,
    resetPasswordToken: data.resetPasswordToken,
  }
  const response = await axiosRequest<MagentoResetPasswordResponse>(
    resetPassword(variables)
  )
  const updateSuccess = response.data?.resetPassword
  const invalidToken =
    response.errors &&
    response.errors[0].message.includes('token is mismatched')
  const message = updateSuccess
    ? 'Salasana päivitetty'
    : invalidToken
    ? 'Salasanan resetointilinkki on vanhenunut. Pyydä uusi linkki ja yritä uudelleen'
    : unexpectedErrorDefaultMessage
  return {
    success: updateSuccess || false,
    message,
  }
}

export type ResetPasswordData = {
  email: string
  newPassword: string
  resetPasswordToken: string
}

export type ResetPasswordResponse = {
  success: boolean
  message: string
}
