import cartAddressFragment, { MagentoCartAddress } from './cartAddressFragment'
import cartItemPricesFragment, {
  MagentoCartItemPrices,
} from './cartItemPricesFragment'
import cartItemProductFragment, {
  MagentoCartItemProduct,
} from './cartItemProductFragment'
import cartPricesFragment, { MagentoCartPrices } from './cartPricesFragment'

const cartFragment = `        
	id
	items {
		id
		product {
			${cartItemProductFragment}
		}
		quantity
		prices {
			${cartItemPricesFragment}
		}
	}
	total_quantity
	applied_coupons {
		code
	}
	prices {
		${cartPricesFragment}
	}
	email
	billing_address {
		${cartAddressFragment}
	}
	selected_payment_method {
		code
	}
`
export default cartFragment

export type MagentoCart = {
  id: string
  items?: MagentoCartItem[] | []
  total_quantity?: number
  applied_coupons?: {
    code: string
  }
  prices?: MagentoCartPrices
  email?: string
  billing_address?: MagentoCartAddress
  selected_payment_method?: {
    code: string
  }
}

export type MagentoCartItem = {
  id: number
  product: MagentoCartItemProduct
  quantity: number
  prices: MagentoCartItemPrices
}
