import React, { useContext, useEffect } from 'react'
import { localStorageKeyNames, resetLocalStorage } from 'utils/localStorage'

import { CartContext } from './providers/CartProvider'
import { CustomerContext } from './providers/CustomerProvider'
import { IsLoggedIn } from './reducers/loginReducer'
import { LoginContext } from './providers/LoginProvider'
import { NotificationContext } from './providers/NotificationProvider'
import { userIsLoggedInGA } from 'gtag.js/utils'

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  const {
    isLoggedIn,
    setLoginState,
    customerToken: stateCustomerToken,
  } = useContext(LoginContext)
  const {
    cartId: stateCartId,
    createCart,
    loadGuestCart,
    loadCustomerCart,
    setCartId,
    mergeGuestCartToCustomerCart,
    resetLocalCart,
  } = useContext(CartContext)
  const { loadCustomer, resetCustomerInfo } = useContext(CustomerContext)
  const { setNotification } = useContext(NotificationContext)

  const isNewPageLoadWithoutPreviousVisit = (data: {
    isLoggedIn: IsLoggedIn
    localStorageCustomerToken: string | null
    localStorageCartId: string | null
  }): boolean =>
    data.isLoggedIn === undefined &&
    !data.localStorageCustomerToken &&
    !data.localStorageCartId

  const isNewPageLoadWithPreviousNotLoggedInVisit = (data: {
    isLoggedIn: IsLoggedIn
    localStorageCustomerToken: string | null
    localStorageCartId: string | null
  }): boolean => {
    return (
      data.isLoggedIn === undefined &&
      !data.localStorageCustomerToken &&
      data.localStorageCartId !== null &&
      data.localStorageCartId !== undefined
    )
  }

  const isNewPageLoadWithPreviousLoggedInVisit = (data: {
    isLoggedIn: IsLoggedIn
    localStorageCustomerToken: string | null
    localStorageCartId: string | null
  }): boolean => {
    return (
      data.isLoggedIn === undefined &&
      data.localStorageCustomerToken !== null &&
      data.localStorageCartId !== null
    )
  }

  const justLoggedIn = (stateCustomerToken?: string | null) =>
    stateCustomerToken !== null && stateCustomerToken !== undefined

  const justLoggedOut = (stateCustomerToken?: string | null) =>
    stateCustomerToken === null

  const setLocalStorageCartId = (cartId?: string): void =>
    cartId
      ? localStorage.setItem(localStorageKeyNames.cartId, cartId)
      : undefined

  const setLocalStorageCustomerToken = (customerToken?: string): void =>
    customerToken
      ? localStorage.setItem(localStorageKeyNames.customerToken, customerToken)
      : undefined

  const customerGuestCart = (cartId?: string | null): string | undefined => {
    const localStorageCartId = localStorage.getItem(localStorageKeyNames.cartId)
    if (cartId !== localStorageCartId && localStorageCartId !== null) {
      return localStorageCartId
    }
    return undefined
  }

  // For google analytics "user_is_logged_in" event
  useEffect(() => {
    isLoggedIn && userIsLoggedInGA()
  }, [isLoggedIn])

  // When first loading the page
  useEffect(() => {
    const localStorageCartId = localStorage.getItem(localStorageKeyNames.cartId)
    const localStorageCustomerToken = localStorage.getItem(
      localStorageKeyNames.customerToken
    )
    if (
      isNewPageLoadWithoutPreviousVisit({
        isLoggedIn,
        localStorageCustomerToken,
        localStorageCartId,
      })
    ) {
      createGuestCart()
    } else if (
      isNewPageLoadWithPreviousNotLoggedInVisit({
        isLoggedIn,
        localStorageCustomerToken,
        localStorageCartId,
      })
    ) {
      tryLoadingGuestCart(localStorageCartId as string)
    } else if (
      isNewPageLoadWithPreviousLoggedInVisit({
        isLoggedIn,
        localStorageCustomerToken,
        localStorageCartId,
      })
    ) {
      setLoginState(true, localStorageCustomerToken) // This triggers nex useEffect hook
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When login/logout
  useEffect(() => {
    if (justLoggedIn(stateCustomerToken)) {
      setNotification(
        {
          type: 'success',
          message: 'Sisäänkirjautuminen onnistui',
        },
        3000
      )
      tryLoadingCustomer({
        customerToken: stateCustomerToken,
      })
    } else if (justLoggedOut(stateCustomerToken)) {
      //Clean up after logout
      resetLocalStorage()
      resetCustomerInfo()
      setCartId(null)
      setNotification(
        {
          type: 'success',
          message: 'Uloskirjautuminen onnistui',
        },
        3000
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomerToken])

  // When cart is removed after loagout
  useEffect(() => {
    if (stateCartId === null) {
      resetLocalCart()
      createGuestCart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCartId])

  async function createGuestCart() {
    try {
      const guestCartId = await createCart()
      setLocalStorageCartId(guestCartId)
    } catch (err) {
      console.error('Failed creating guest cart', err)
    }
  }

  async function tryLoadingGuestCart(cartId: string): Promise<void> {
    try {
      await loadGuestCart(cartId)
    } catch (err) {
      setLocalStorageCartId(undefined)
      console.warn('Failed loading guest cart, creating new guest cart', err)
      createGuestCart()
    }
  }

  async function tryLoadingCustomer(data: {
    customerToken?: string | null
  }): Promise<void> {
    try {
      if (!data.customerToken) {
        throw new Error('Can not load customer, customer token expected')
      }
      let customerCartId = await loadCustomerCart(data.customerToken)
      await loadCustomer(data.customerToken)
      if (window.location.pathname.includes('order-success')) {
        customerCartId = await createCart(data.customerToken)
      } else {
        const guestCartId = customerGuestCart(customerCartId)
        if (guestCartId) {
          await mergeGuestCartToCustomerCart(
            guestCartId,
            customerCartId,
            data.customerToken
          )
        }
      }
      setLocalStorageCustomerToken(data.customerToken)
      setLocalStorageCartId(customerCartId)
    } catch (err) {
      console.error('Failed loading customer', err)
      setLoginState(false, null)
    }
  }

  return <>{children}</>
}
