/**
 *
 * @param isError
 * @returns  '✗ ' if isError === true, '✓ ' if isError === false, '' if isError === undefined
 */
export const checkMark = (isError: boolean | undefined) =>
  isError === true ? '✗ ' : isError === false ? '✓ ' : ''

export const capitalizeFirstLetter = (sentence: string) =>
  sentence.charAt(0).toUpperCase() + sentence.slice(1)
