import updateCustomerV2, {
  MagentoUpdateCustomerV2Response,
} from 'requests/graphql/mutations/updateCustomerV2Mutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerData: { firstname: string; lastname: string },
  customerToken: string
): Promise<MagentoUpdateCustomerV2Response> => {
  const { firstname, lastname } = customerData
  const response = await axiosRequest<MagentoUpdateCustomerV2Response>(
    updateCustomerV2({ firstname, lastname }),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed setting customer name')
  }
  return response
}
