import React, { useContext, useEffect, useState } from 'react'

import { CartContext } from 'state/providers/CartProvider'
import { CgShoppingCart } from 'react-icons/cg'
import { FaRegUser } from 'react-icons/fa'
import Hamburger from 'hamburger-react'
import { Link } from 'gatsby'
import { LoginContext } from 'state/providers/LoginProvider'
import Logo from './img/mehilainen_logo.svg'
import LogoSquare from './img/mehilainen_logo_square.svg'
import LogoutButton from './LogoutButton'
import Notification from './Notification'
import { NotificationContext } from 'state/providers/NotificationProvider'
import styleTokens from 'styleTokens'
import styled from 'styled-components'

export default () => {
  const [currentUrl, setCurrentUrl] = useState<string>('')
  const { notification } = useContext(NotificationContext)
  const { isLoggedIn, logoutCustomer, customerToken } = useContext(LoginContext)
  const profileUrl = isLoggedIn ? '/profile/' : '/login/'

  useEffect(() => {
    setCurrentUrl(typeof window !== 'undefined' ? window.location.pathname : '')
  }, [])

  const handleLogout = () => {
    if (customerToken) {
      logoutCustomer(customerToken)
    }
  }

  return (
    <NavContainer>
      <WidthContainer>
        <DesktopContent
          currentUrl={currentUrl}
          isLoggedIn={isLoggedIn ? true : false}
          handleLogout={handleLogout}
          setCurrentUrl={setCurrentUrl}
          profileUrl={profileUrl}
        />
        <MobileContent
          currentUrl={currentUrl}
          isLoggedIn={isLoggedIn ? true : false}
          handleLogout={handleLogout}
          setCurrentUrl={setCurrentUrl}
          profileUrl={profileUrl}
        />
      </WidthContainer>
      <Notification message={notification} />
    </NavContainer>
  )
}

interface ContentProps {
  currentUrl: string
  isLoggedIn: boolean
  handleLogout: () => void
  profileUrl: string
  setCurrentUrl: (url: string) => void
}

const DesktopContent = ({
  currentUrl,
  handleLogout,
  isLoggedIn,
  profileUrl,
  setCurrentUrl,
}: ContentProps) => {
  return (
    <Desktop>
      <LogoAndLinks>
        <StyledLink
          href="https://mehilainen.fi"
          onClick={() => setCurrentUrl('/')}
        >
          <img src={Logo} alt="Mehiläinen logo" />
        </StyledLink>
        <ul>
          {NavLinks.map(link => (
            <NavLi key={link.text} current={currentUrl === link.url}>
              <Link to={link.url} onClick={() => setCurrentUrl(link.url)}>
                {link.text}
              </Link>
            </NavLi>
          ))}
        </ul>
      </LogoAndLinks>
      <Icons>
        <NavLi current={currentUrl === '/cart/'}>
          <Cart clickHandler={() => setCurrentUrl('/cart/')} />
        </NavLi>
        <NavLi current={currentUrl === profileUrl}>
          <Link to={profileUrl} onClick={() => setCurrentUrl(profileUrl)}>
            <FaRegUser size="24px" />
          </Link>
        </NavLi>
        {isLoggedIn ? <LogoutButton handleLogout={handleLogout} /> : null}
      </Icons>
    </Desktop>
  )
}

const MobileContent = ({
  currentUrl,
  handleLogout,
  isLoggedIn,
  profileUrl,
  setCurrentUrl,
}: ContentProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  function handleLinkClick(newUrl: string) {
    setMenuOpen(false)
    setCurrentUrl(newUrl)
  }

  return (
    <Mobile menuOpen={menuOpen}>
      <FirstRow>
        <StyledLink
          href="https://mehilainen.fi"
          onClick={() => handleLinkClick('/')}
        >
          <img src={LogoSquare} alt="Mehiläinen logo" />
        </StyledLink>
        <MobileIcons>
          <NavLi current={currentUrl === '/cart/'}>
            <Cart clickHandler={() => handleLinkClick('/cart/')} />
          </NavLi>
          <NavLi current={currentUrl === profileUrl}>
            <Link to={profileUrl} onClick={() => handleLinkClick(profileUrl)}>
              <FaRegUser size="24px" />
            </Link>
          </NavLi>
          {isLoggedIn ? (
            <LogoutButton
              handleLogout={() => {
                setMenuOpen(false)
                handleLogout()
              }}
            />
          ) : null}
          <Hamburger
            label="navigation menu"
            toggle={setMenuOpen}
            toggled={menuOpen}
          />
        </MobileIcons>
      </FirstRow>
      <SecondRow menuOpen={menuOpen}>
        <ul>
          {NavLinks.map(link => (
            <NavLi key={link.text} current={currentUrl === link.url}>
              <Link to={link.url} onClick={() => handleLinkClick(link.url)}>
                {link.text}
              </Link>
            </NavLi>
          ))}
        </ul>
      </SecondRow>
    </Mobile>
  )
}

const Cart = ({ clickHandler }: { clickHandler?: () => void }) => {
  const { total_quantity } = useContext(CartContext)

  return (
    <Link to="/cart" onClick={clickHandler}>
      <CartContainer>
        <CgShoppingCart size="24px" />
        <CartQuantity
          aria-live="polite"
          aria-label={`${
            total_quantity > 1
              ? total_quantity + ' tuotetta'
              : total_quantity + ' tuote'
          } ostoskorissa`}
        >
          {total_quantity}
        </CartQuantity>
      </CartContainer>
    </Link>
  )
}

const NavContainer = styled.nav`
  width: 100%;
  font-size: 16px;
  margin-inline: auto;
  margin-block-start: calc(var(--content-container-inline-padding) * 2);
  margin-block-end: var(--content-container-inline-padding);
  ul {
    display: flex;
    gap: 30px;
    align-items: center;
  }
`

const WidthContainer = styled.div`
  margin: auto;
  padding-inline: var(--content-container-inline-padding);
  @media (min-width: ${styleTokens['content-container-max-width']}) {
    padding-inline: 0px;
  }
`

const Desktop = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0;
  }
`

const LogoAndLinks = styled.div`
  display: flex;
  gap: 50px;

  img {
    max-width: 200px;
    display: flex;
  }
`

const Mobile = styled.div<{ menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: height 0.3s ease-in;
  overflow: hidden;
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    display: none;
  }
`

const MobileIcons = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  > a {
    display: grid;
  }
`

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SecondRow = styled.div<{ menuOpen: boolean }>`
  ul {
    display: ${props => (props.menuOpen ? 'flex' : 'none')};
    padding-top: 15px;
    flex-direction: column;
    align-items: end;
    gap: 16px;
  }
`

const CartQuantity = styled.div`
  position: absolute;
  color: white;
  background: ${props => props.theme.primaryGreen};
  border-radius: 50%;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`

const CartContainer = styled.div`
  position: relative;
  display: flex;
`

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const NavLi = styled.li<{ current: boolean }>`
  list-style: none;
  a {
    font-weight: 350;
    font-size: 18px;
    color: ${props => (props.current ? props.theme.primaryGreen : 'inherit')};
  }
`

const NavLinks = [
  {
    url: '/',
    text: 'Etusivu',
  },
  {
    url: '/tarkastukset/',
    text: 'Terveystarkastukset',
  },
  {
    url: '/lahjakortti/',
    text: 'Lahjakortti',
  },
]

const StyledLink = styled.a`
  &.external-link {
    position: relative;

    &::after {
      content: none !important;
    }
  }

  &[href^='http']:after,
  &[href^='https']:after {
    content: none !important;
  }
`
