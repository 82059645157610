import setPaymentMethodOnCartMutation, {
  MagentoSetPaymentMethodOnCartResponse,
  PaymentMethod,
} from 'requests/graphql/mutations/setPaymentMethodOnCartMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

//We use only paytrail as payment method
export default async (
  cartId: string,
  customerToken?: string | null
): Promise<MagentoSetPaymentMethodOnCartResponse> => {
  const response = await axiosRequest<MagentoSetPaymentMethodOnCartResponse>(
    setPaymentMethodOnCartMutation({
      cartId,
      paymentMethod: allowedPaymentMethods[0] as PaymentMethod,
    }),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed setting payment method')
  }
  return response
}

export const allowedPaymentMethods = ['paytrail']
