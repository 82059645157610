import { MagentoCart } from 'requests/graphql/fragments/cartFragment'

type EmptyCartContent = {
  items: []
  total_quantity: 0
  email: ''
}

export const emptyCartContent: EmptyCartContent = {
  items: [],
  total_quantity: 0,
  email: '',
}

type InitialCartState = {
  cartId: undefined
  cartContent: EmptyCartContent
}

export const initialCartState: InitialCartState = {
  cartId: undefined,
  cartContent: emptyCartContent,
}

export default (state: Cart, action: CartAction): Cart => {
  switch (action.type) {
    case 'SET_CART_ID':
      if (typeof action.payload === 'string' || action.payload === null) {
        return {
          ...state,
          cartId: action.payload,
        }
      }
      break
    case 'SET_ALL_CONTENT':
      if (action.payload !== null && typeof action.payload === 'object') {
        return {
          ...state,
          cartContent: action.payload,
        }
      }
      break
    case 'RESET_CONTENT':
      if (action.payload !== null) {
        return {
          ...state,
          cartContent: {
            ...state.cartContent,
            items: [],
            total_quantity: 0,
            prices: undefined,
          },
        }
      }
      break
    default:
      return state
  }
  return state
}

type CartAction = {
  payload: ActionPayload
  type: CartActionType
}

export type Cart = {
  cartId?: string | null
  cartContent?: CartContent | Record<string, never>
}

export type CartContent = Omit<MagentoCart, 'id'>

export type ActionPayload = CartContent | string | null

export type CartActionType = 'SET_CART_ID' | 'SET_ALL_CONTENT' | 'RESET_CONTENT'
