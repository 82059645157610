import axios from 'axios'

const graphql_endpoint = process.env.GATSBY_MAGENTO_GRAPHQL_ENDPOINT
  ? process.env.GATSBY_MAGENTO_GRAPHQL_ENDPOINT
  : ''

const customHeaders = {
  'Content-Type': 'application/json',
}

const headers = (token?: string | null) => {
  return token
    ? {
        ...customHeaders,
        Authorization: `Bearer ${token}`,
      }
    : customHeaders
}

export const axiosRequest = async <T>(
  graphqlQuery: GraphqlQuery,
  token?: string | null
): Promise<T> => {
  const result = await axios({
    url: graphql_endpoint,
    method: 'post',
    headers: headers(token),
    data: graphqlQuery,
  })

  if (result.status === 200) {
    return result.data
  } else {
    console.error('Response !== 200', result)
    throw new Error('Response !== 200')
  }
}
