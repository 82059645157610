import CartProvider from './providers/CartProvider'
import CustomerProvider from './providers/CustomerProvider'
import LoginProvider from './providers/LoginProvider'
import NotificationProvider from './providers/NotificationProvider'
import React from 'react'
import StateSynchronizer from './StateSynchronizer'

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  return (
    <LoginProvider>
      <CustomerProvider>
        <CartProvider>
          <NotificationProvider>
            <StateSynchronizer>{children}</StateSynchronizer>
          </NotificationProvider>
        </CartProvider>
      </CustomerProvider>
    </LoginProvider>
  )
}
