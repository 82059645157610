import removeItemFromCartMutation, {
  MagentoRemoveItemFromCartResponse,
} from 'requests/graphql/mutations/removeItemFromCartMutation'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  cartId: string,
  itemId: number,
  customerToken?: string | null
): Promise<MagentoRemoveItemFromCartResponse> => {
  const response = await axiosRequest<MagentoRemoveItemFromCartResponse>(
    removeItemFromCartMutation({ cartId, itemId }),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error(`Failed removing item ${itemId} from cart`)
  }
  return response
}
