import { MagentoErrorResponse } from 'utils/errorHandling'

export default (
  variables: UpdateCustomerIsSubscribedVariables
): GraphqlQuery => {
  const { is_subscribed } = variables

  return {
    query: `
			mutation updateCustomerIsSubscribed(
				$is_subscribed: Boolean!
			) {
				updateCustomerV2(
					input: {
						is_subscribed: $is_subscribed
					}
				) {
					customer {
						is_subscribed
					}
				}
			}
		`,
    variables: {
      is_subscribed,
    },
  }
}

export type UpdateCustomerIsSubscribedVariables = {
  is_subscribed: boolean
}

export type MagentoUpdateCustomerIsSubscribedResponse = MagentoErrorResponse & {
  data?: {
    updateCustomerV2: {
      customer: {
        is_subscribed: boolean
      }
    }
  }
}
