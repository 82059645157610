import { MagentoErrorResponse } from 'utils/errorHandling'

export default (): GraphqlQuery => {
  return { query: `mutation { createEmptyCart }` }
}

export type MagentoNewCartResponse = MagentoErrorResponse & {
  data?: {
    createEmptyCart: string
  }
}
