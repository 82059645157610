import customerCartQuery, {
  MagentoCustomerCartResponse,
} from 'requests/graphql/queries/customerCartQuery'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerToken: string
): Promise<MagentoCustomerCartResponse> => {
  const response = await axiosRequest<MagentoCustomerCartResponse>(
    customerCartQuery(),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed fetching customer cart')
  }
  return response
}
