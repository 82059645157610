import { MagentoErrorResponse } from 'utils/errorHandling'

export default (
  variables: ChangeCustomerPasswordRequestVariables
): GraphqlQuery => {
  const { currentPassword, newPassword } = { ...variables }

  return {
    query: `
		mutation changeCustomerPassword(
			$currentPassword: String!,
			$newPassword: String!
		){
			changeCustomerPassword(
				currentPassword: $currentPassword
				newPassword: $newPassword
			) {
				id
				email
			}
		}
		`,
    variables: {
      currentPassword,
      newPassword,
    },
  }
}

export type MagentoChangeCustomerPasswordResponse = MagentoErrorResponse & {
  data?: {
    changeCustomerPassword: {
      id: number
      email: string
    }
  }
}

type ChangeCustomerPasswordRequestVariables = {
  currentPassword: string
  newPassword: string
}
