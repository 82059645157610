import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: MergeCartsVariables): GraphqlQuery => {
  const { sourceCartId, destinationCartId } = variables
  return {
    query: `
		mutation mergeCarts($sourceCartId: String!, $destinationCartId: String! ) {
			mergeCarts(
				source_cart_id: $sourceCartId,
				destination_cart_id: $destinationCartId 
				) {
					${cartFragment}
			} 
		}
		`,
    variables: { sourceCartId, destinationCartId },
  }
}

type MergeCartsVariables = {
  sourceCartId: string
  destinationCartId: string
}

export type MagentoMergeCartsResponse = MagentoErrorResponse & {
  data?: {
    mergeCarts: MagentoCart
  }
}
