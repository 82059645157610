const customerAddressFragment = `
	id
	firstname
	lastname
	street
	postcode
	city
	region {
		region_code
		region
	}
	country_code
	telephone
`
export default customerAddressFragment

export type MagentoCustomerAddress = {
  id?: number
  firstname?: string
  lastname?: string
  street?: string[]
  postcode?: string
  city?: string
  region?: MagentoRegion
  country_code?: string
  telephone?: string
}

export type MagentoRegion = {
  region_code: string
  region: string
}
