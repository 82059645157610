import { axiosRequest } from 'requests/axios/axiosRequests'
import revokeCustomerToken, {
  MagentoRevokeCustomerTokenResponse,
} from 'requests/graphql/mutations/revokeCustomerTokenMutation'

export default async (customerToken: string): Promise<boolean> => {
  try {
    await axiosRequest<MagentoRevokeCustomerTokenResponse>(
      revokeCustomerToken(),
      customerToken
    )
  } catch (err) {
    console.error('Token revocation failed', err)
  }
  return true
}
