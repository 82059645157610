import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { mehilainenTheme } from '@mehilainen/design-system'

const verkkokauppaTheme = {
  ...mehilainenTheme,
  mobileBreakpoint: '760px',
  tabletBreakpoint: '1300px',
  primaryGreen: mehilainenTheme.colors.primary[500],
  secondaryGreen: mehilainenTheme.colors.primary.base,
}
declare module 'styled-components' {
  type Theme = typeof verkkokauppaTheme
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default (data: { element: React.ReactElement }) => (
  <StyledThemeProvider theme={verkkokauppaTheme}>
    {data.element}
  </StyledThemeProvider>
)
