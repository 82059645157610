import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: SetPaymentMethodOnCartVariables): GraphqlQuery => {
  const { cartId, paymentMethod } = { ...variables }
  return {
    query: `
      mutation setPaymentMethodOnCart(
        $cartId: String!,
				$paymentMethod: String!,
        ) {
          setPaymentMethodOnCart(
          input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethod
            }
          }
        ) {
          cart {
            ${cartFragment}
            }
          }
      }`,
    variables: {
      cartId,
      paymentMethod,
    },
  }
}

export type SetPaymentMethodOnCartVariables = {
  cartId: string
  paymentMethod: PaymentMethod
}

export type PaymentMethod = 'paytrail'

export type MagentoSetPaymentMethodOnCartResponse = MagentoErrorResponse & {
  data?: {
    cart: MagentoCart
  }
}
