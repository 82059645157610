import updateCustomerIsSubscribed, {
  MagentoUpdateCustomerIsSubscribedResponse,
  UpdateCustomerIsSubscribedVariables,
} from 'requests/graphql/mutations/updateCustomerIsSubscribedMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  data: UpdateCustomerIsSubscribedVariables,
  customerToken: string
): Promise<MagentoUpdateCustomerIsSubscribedResponse> => {
  const response =
    await axiosRequest<MagentoUpdateCustomerIsSubscribedResponse>(
      updateCustomerIsSubscribed(data),
      customerToken
    )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed setting customer name')
  }
  return response
}
