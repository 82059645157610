import React, { createContext, useReducer } from 'react'
import notificationReducer, {
  initialNotificationState,
} from '../reducers/notificationReducer'

import { NotificationMessage } from 'components/Notification'

// Initialize the context with an empty object that respects the types
export const NotificationContext = createContext<NotificationProvider>({
  notification: {},
  removeNotification: () => {
    return
  },
  setNotification: () => {
    return
  },
})

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  const [state, dispatch] = useReducer(
    notificationReducer,
    initialNotificationState
  )

  const removeNotification = () => {
    dispatch({ type: 'REMOVE_NOTIFICAITON', payload: {} })
  }

  const setNotification = (
    notification: NotificationMessage,
    timeout?: number
  ) => {
    dispatch({ type: 'SET_NOTIFICATION', payload: notification })
    if (timeout) {
      setTimeout(removeNotification, timeout)
    }
  }

  const value: NotificationProvider = {
    notification: state,
    removeNotification,
    setNotification,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

type NotificationProvider = {
  notification: NotificationMessage
  removeNotification: () => void
  setNotification: (notification: NotificationMessage, timeout?: number) => void
}
