import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: SetBillingAddressOnCartVariables): GraphqlQuery => {
  const {
    cartId,
    firstName,
    lastName,
    company,
    city,
    street,
    postcode,
    telephone,
    countryId,
  } = { ...variables }
  return {
    query: `
      mutation setBillingAddressOnCart(
        $cartId: String!,
        $firstName: String!,
        $lastName: String!,
        $company: String!,
        $street: [String]!,
        $city: String!,
        $postcode: String!,
        $telephone: String!,
        $countryId: String!,
        ) {
        setBillingAddressOnCart(
          input: {
            cart_id: $cartId
            billing_address: {
              address: {
                firstname: $firstName
                lastname: $lastName
                company: $company
                street: $street
                city: $city
                postcode: $postcode
                country_code: $countryId
                telephone: $telephone
                save_in_address_book: true
              }
            }
          }
        ) {
          cart {
						${cartFragment}
          }
        }
      }`,
    variables: {
      cartId,
      firstName,
      lastName,
      company,
      street,
      city,
      postcode,
      telephone,
      countryId,
    },
  }
}

export type SetBillingAddressOnCartVariables = {
  cartId: string | null
  firstName: string
  lastName: string
  company: string
  street: string[]
  city: string
  postcode: string
  telephone: string
  countryId: string
}

export type MagentoSetBillingAddressResponse = MagentoErrorResponse & {
  data?: {
    setBillingAddressOnCart: {
      cart: MagentoCart
    }
  }
}
