import createCustomer, {
  CreateCustomerRequestVariables,
  MagentoCrateCustomerResponse,
} from 'requests/graphql/mutations/createCustomerV2Mutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  newCustomerData: NewCustomerData
): Promise<MagentoCrateCustomerResponse> => {
  const response = await axiosRequest<MagentoCrateCustomerResponse>(
    createCustomer(newCustomerData)
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed creating new customer')
  }
  return response
}

export type NewCustomerData = CreateCustomerRequestVariables
