import customerFragment, {
  MagentoCustomer,
} from '../fragments/customerFragment'

import { MagentoCustomerOrdersList } from '../fragments/customerOrdersFragment'
import { MagentoErrorResponse } from 'utils/errorHandling'

export default (): GraphqlQuery => {
  return {
    query: `
		query magento {
			customer {
				${customerFragment}
			}
		}
		`,
  }
}

export type MagentoCustomerDataResponse = MagentoErrorResponse & {
  data?: {
    customer?: MagentoCustomer
    customerOrders: MagentoCustomerOrdersList
  }
}
