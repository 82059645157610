import customerData, {
  MagentoCustomerDataResponse,
} from 'requests/graphql/queries/customerDataQuery'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerToken: string
): Promise<MagentoCustomerDataResponse> => {
  const response = await axiosRequest<MagentoCustomerDataResponse>(
    customerData(),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed laoding customer data')
  } else {
    return response
  }
}
