import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: GenerateCustomerTokenVariables): GraphqlQuery => {
  const { email, password } = { ...variables }
  return {
    query: `
		mutation generateCustomerToken(
			$email: String!,
			$password: String!,
		){
			generateCustomerToken(
				email: $email
				password: $password
			) {
				token
			}
		}		
		`,
    variables: {
      email,
      password,
    },
  }
}

type GenerateCustomerTokenVariables = {
  email: string
  password: string
}

export type MagentoGenerateCustomerTokenResponse = MagentoErrorResponse & {
  data?: {
    generateCustomerToken: {
      token?: string
    }
  }
}
