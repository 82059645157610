import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: RemoveItemFromCartVariables): GraphqlQuery => {
  const { cartId, itemId } = { ...variables }
  return {
    query: `
		mutation removeItemFromCart($cartId: String!, $itemId: Int!,  ) {
			removeItemFromCart(
				input: {
					cart_id: $cartId,
					cart_item_id: $itemId
				}
			) {
				cart {
					${cartFragment}
				}
			}
		}`,
    variables: {
      cartId,
      itemId,
    },
  }
}

export type RemoveItemFromCartVariables = {
  cartId: string
  itemId: number
}

export type MagentoRemoveItemFromCartResponse = MagentoErrorResponse & {
  data?: {
    removeItemFromCart: {
      cart: MagentoCart
    }
  }
}
