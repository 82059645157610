export const initialLoginState: LoginState = {
  isLoggedIn: undefined,
  customerToken: undefined,
}

export default (state: LoginState, action: Action): LoginState => {
  switch (action.type) {
    case 'SET_CUSTOMER_TOKEN':
      return {
        ...state,
        customerToken: action.payload,
      }
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true,
      }
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
      }
    default:
      return state
  }
}

export type LoginState = {
  isLoggedIn: IsLoggedIn
  customerToken?: string | null
}

export type IsLoggedIn = boolean | undefined
type Action = {
  type: 'LOGIN' | 'LOGOUT' | 'SET_CUSTOMER_TOKEN'
  payload?: string | null
}
