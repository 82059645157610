import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (): GraphqlQuery => {
  return {
    query: `
      query customerCart {
				customerCart{
					${cartFragment}
				}	
				
      }`,
  }
}

export type MagentoCustomerCartResponse = MagentoErrorResponse & {
  data?: {
    customerCart: MagentoCart
  }
}
