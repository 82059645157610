import React, { useContext } from 'react'

import { Notification } from '@mehilainen/design-system'
import { NotificationContext } from 'state/providers/NotificationProvider'
import styled from 'styled-components'

export default ({ message }: Props) => {
  const { removeNotification } = useContext(NotificationContext)

  return (
    <>
      {message.type && message.message && (
        <Container>
          <Notification
            type={message.type}
            message={message.message}
            handleRemove={removeNotification}
          />
        </Container>
      )}
    </>
  )
}

type Props = {
  message: NotificationMessage
}

export type NotificationMessage = {
  type?: NotificationMessageType
  message?: string
}

type NotificationMessageType = 'success' | 'error' | 'warning'

const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  width: 98vw;
  z-index: 2;
  article > div > div {
    flex-wrap: wrap;
    gap: 8px;
    line-break: anywhere;
  }
`
