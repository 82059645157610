import Logo from './img/logo.svg'
import React from 'react'
import styleTokens from 'styleTokens'
import styled from 'styled-components'

const footerLinks = [
  {
    title: 'VERKKOKAUPPA',
    links: [
      {
        name: 'Terveystarkastukset',
        url: '/tarkastukset',
      },
      {
        name: 'Lahjakortti',
        url: '/lahjakortti',
      },
    ],
  },
  {
    title: 'LISÄTIETOA',
    links: [
      {
        name: 'Kysymyksiä ja vastauksia',
        url: '/kysymyksia-ja-vastauksia',
      },
      {
        name: 'Verkkokaupan sopimus- ja toimitusehdot',
        url: 'https://media.mehilainen.fi/www/pdf/yleiset_verkkokaupan_sopimus-_ja_toimitusehdot.pdf',
      },
      {
        name: 'Terveyspalveluita koskevat yleiset ehdot',
        url: 'https://mehilainen.fi/terveyspalveluiden-yleiset-ehdot',
      },
      {
        name: 'Mehiläisen tietosuojaselosteet',
        url: 'https://mehilainen.fi/rekisteriselosteet',
      },
      {
        name: 'Ota yhteyttä',
        url: '/ota-yhteytta',
      },
    ],
  },
]

export default () => {
  const isExternalLink = (url: string) =>
    url.startsWith('http://') || url.startsWith('https://')

  return (
    <StyledFooter>
      <Content>
        <div>
          <img src={Logo} alt="Mehiläinen logo" />
          <Title>Mehiläinen Oy</Title>
          <p>
            Mehiläisen terveyspalvelut ja <br /> ajanvarauksen löydät <br />
            osoitteesta{' '}
            <a
              className="custom-white-link"
              href="https://mehilainen.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.mehilainen.fi
            </a>
          </p>
        </div>
        {footerLinks.map(fl => (
          <div key={fl.title}>
            <Title>{fl.title}</Title>
            <Links>
              {fl.links.map(link => {
                const linkAttributes = {
                  key: link.name,
                  href: link.url,
                  target: '_self',
                  rel: '',
                }

                if (isExternalLink(link.url)) {
                  linkAttributes.target = '_blank'
                  linkAttributes.rel = 'noopener noreferrer'
                }

                return <a {...linkAttributes}>{link.name}</a>
              })}
            </Links>
          </div>
        ))}
      </Content>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.primary[600]};
  color: white;
  margin-top: 80px !important;

  a[href^="http"]::after,
  a[href^="https://"]::after
  {
    content: '';
    width: 11px;
    height: 11px;
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-bottom: 0.5ex;
  }
`

const Content = styled.div`
  margin-inline: auto;
  margin-block: 80px 80px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-inline: var(--content-container-inline-padding);
  padding-left: 24px;
  @media (min-width: ${styleTokens['content-container-max-width']}) {
    padding-inline: 0px;
  }

  > div {
    width: 100%;
  }

  img {
    width: 100px;
    margin-bottom: 15px;
  }

  p {
    word-wrap: break-word;
  }

  .custom-white-link {
    color: white;
    text-decoration: underline;
  }

  & > div:first-child {
    text-align: left;
    line-height: 1.7;
  }
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    flex-direction: row;
    div:first-child {
      text-align: inherit;
    }
  }
`

const Title = styled.div`
  color: white;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 15px;
  opacity: 0.5;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    font-weight: 350;
    font-size: 16px;
    color: white;
    text-decoration: none;
    position: relative;
    transition: color 0.3s, text-decoration 0.3s;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`
