import * as customerService from 'requests/customerRequests'

import React, { createContext, useReducer } from 'react'
import customerReducer, {
  CustomerInfoData,
  CustomerOrdersList,
  emptyCustomerAddress,
  emptyCustomerInfo,
  emptyCustomerOrders,
} from '../reducers/customerReducer'

import { MagentoCustomer } from 'requests/graphql/fragments/customerFragment'
import { MagentoCustomerAddress } from 'requests/graphql/fragments/customerAddressFragment'
import { MagentoCustomerOrdersList } from 'requests/graphql/fragments/customerOrdersFragment'

// Initialize the context with an empty object that respects the types
export const CustomerContext = createContext<CustomerProvider>({
  customerAddress: emptyCustomerAddress,
  customerInfo: emptyCustomerInfo,
  customerOrders: emptyCustomerOrders,
  loadCustomer: () => {
    return Promise.resolve(false)
  },
  resetCustomerInfo: () => {
    return
  },
  setCustomerInfo: () => {
    return
  },
  setCustomerOrders: () => {
    return
  },
  updateAddress: () => {
    return Promise.resolve(false)
  },
  updateEmail: () => {
    return Promise.resolve('')
  },
  updateMarketingPermission: () => Promise.resolve(false),
  updateName: () => {
    return Promise.resolve(emptyCustomerInfo)
  },
})

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  const [state, dispatch] = useReducer(customerReducer, emptyCustomerInfo)

  const customerAddress = (): MagentoCustomerAddress | undefined => {
    if ('addresses' in state) {
      return state.addresses && state.addresses[0]
    }
    return undefined
  }

  const customerInfo = (): CustomerInfoData => {
    if (Object.keys(state).length) {
      return state as CustomerInfoData
    }
    return emptyCustomerInfo
  }

  const customerOrders = (): MagentoCustomerOrdersList =>
    state.orders || emptyCustomerOrders

  const loadCustomer = async (customerToken: string): Promise<boolean> => {
    const response = await customerService.getCustomerData(customerToken)
    const customerData = response.data?.customer
    if (customerData) {
      customerData.firstname &&
        dispatch({ type: 'firstname', payload: customerData.firstname })
      customerData.lastname &&
        dispatch({ type: 'lastname', payload: customerData.lastname })
      customerData.email &&
        dispatch({ type: 'email', payload: customerData.email })
      customerData.is_subscribed !== undefined &&
        dispatch({ type: 'is_subscribed', payload: customerData.is_subscribed })
      if (customerData.addresses && customerData.addresses.length) {
        dispatch({ type: 'addresses', payload: customerData.addresses[0] })
      }
      customerData.orders &&
        dispatch({ type: 'orders', payload: customerData.orders })
      return true
    } else {
      throw new Error('Failed loading customer')
    }
  }

  const resetCustomerInfo = (data?: CustomerInfoData) => {
    dispatch({ type: 'RESET', payload: '' })
  }

  const setCustomerInfo = (
    field: keyof CustomerInfoData,
    value: string | MagentoCustomerAddress
  ) => {
    dispatch({ type: field, payload: value })
  }

  const setCustomerOrders = (value: CustomerOrdersList) => {
    dispatch({ type: 'orders', payload: value })
  }

  const updateAddress = async (
    address: Required<MagentoCustomerAddress>,
    customerToken: string
  ): Promise<boolean> => {
    if (!customerToken) {
      throw new Error(
        'Can not set customer address: customer token must be given'
      )
    }
    const noPreviousAddress = !customerAddress()
    const id = customerAddress()?.id ?? false
    if (!noPreviousAddress && id) {
      // We have to remove the existing address because Magento address update does not update
      await customerService.deleteCustomerAddress({ id }, customerToken)
    }
    const response = await customerService.createCustomerAddress(
      address,
      customerToken
    )
    response.data &&
      dispatch({
        type: 'addresses',
        payload: response.data.createCustomerAddress,
      })
    return true
  }

  const updateEmail = async (
    customerToken: string,
    email: string,
    password: string
  ): Promise<string> => {
    if (!email) {
      throw new Error('Can not change email, email missing')
    }
    if (!password) {
      throw new Error('Can not change email, password missing')
    }
    if (!customerToken) {
      throw new Error('Can not change email, customerToken missing')
    }
    const response = await customerService.updateCustomerEmail(
      { email, password },
      customerToken
    )
    if (response.data) {
      const newEmail = response.data.updateCustomerEmail.customer.email
      dispatch({ type: 'email', payload: newEmail })
      return newEmail
    }
    return email
  }

  const updateMarketingPermission = async (
    customerToken: string,
    is_subscribed: boolean
  ): Promise<boolean> => {
    if (!customerToken) {
      throw new Error(
        'Can not update marketing permission: customer token must be given'
      )
    }
    const response = await customerService.updateCustomerIsSubscribed(
      { is_subscribed },
      customerToken
    )
    const responseData = response.data
    if (!responseData) {
      throw new Error('Customer data undefined after requesting name update')
    }
    dispatch({ type: 'is_subscribed', payload: is_subscribed })
    return responseData.updateCustomerV2.customer.is_subscribed
  }

  const updateName = async (
    customerToken: string,
    firstname: string,
    lastname: string
  ): Promise<MagentoCustomer> => {
    if (!firstname || !lastname) {
      throw new Error(
        'Can not set customer names: firstname and lastame can not be undefined'
      )
    }
    if (!customerToken) {
      throw new Error(
        'Can not set customer names: customer token must be given'
      )
    }
    const response = await customerService.updateCustomerName(
      { firstname, lastname },
      customerToken
    )
    const updatedCustomer = response.data?.updateCustomerV2.customer
    if (!updatedCustomer) {
      throw new Error('Customer data undefined after requesting name update')
    }
    firstname &&
      updatedCustomer.firstname &&
      dispatch({ type: 'firstname', payload: updatedCustomer.firstname })
    lastname &&
      updatedCustomer.lastname &&
      dispatch({ type: 'lastname', payload: updatedCustomer.lastname })
    return updatedCustomer
  }

  const value: CustomerProvider = {
    customerAddress: customerAddress(),
    customerInfo: customerInfo(),
    customerOrders: customerOrders(),
    loadCustomer,
    resetCustomerInfo,
    setCustomerInfo,
    setCustomerOrders,
    updateAddress,
    updateEmail,
    updateMarketingPermission,
    updateName,
  }

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}

type CustomerProvider = {
  customerInfo: CustomerInfoData
  customerAddress: MagentoCustomerAddress | undefined
  customerOrders: MagentoCustomerOrdersList
  loadCustomer: (customerToken: string) => Promise<boolean>
  resetCustomerInfo: (data?: CustomerInfoData) => void
  setCustomerInfo: (
    field: keyof CustomerInfoData,
    value: string | MagentoCustomerAddress
  ) => void
  setCustomerOrders: (value: CustomerOrdersList) => void
  updateAddress: (
    address: Required<MagentoCustomerAddress>,
    customerToken: string
  ) => Promise<boolean>
  updateEmail: (
    customerToken: string,
    email: string,
    password: string
  ) => Promise<string>
  updateMarketingPermission: (
    customerToken: string,
    is_subscribed: boolean
  ) => Promise<boolean>
  updateName: (
    customerToken: string,
    firstname: string,
    lastname: string
  ) => Promise<MagentoCustomer>
}
