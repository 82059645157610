import { MagentoErrorResponse } from 'utils/errorHandling'

export default (
  variables: AssignCustomerToGuestCartVariables
): GraphqlQuery => {
  const { cartId } = variables
  return {
    query: `
		mutation assignCustomerToGuestCart($cartId: String!) {
			assignCustomerToGuestCart(cart_id: $cartId) {
				items {
					quantity
					product {
						sku
					}
				}
			} 
		}
		`,
    variables: { cartId },
  }
}

type AssignCustomerToGuestCartVariables = {
  cartId: string
}

export type MagentoAssignCustomerToGuestCartResponse = MagentoErrorResponse & {
  data?: {
    assignCustomerToGuestCart: undefined
  }
}
