export const resetLocalStorage = () => {
  Object.values(localStorageKeyNames).forEach(v => {
    localStorage.removeItem(v)
  })
}

export const localStorageKeyNames = {
  cartId: 'mehi_vk_cart_id',
  customerEmail: 'mehi_vk_customer_email',
  customerToken: 'mehi_vk_customer_token',
}
