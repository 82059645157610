import updateCustomerEmail, {
  MagentoUpdateCustomerEmailResponse,
  UpdateCustomerEmailVariables,
} from 'requests/graphql/mutations/updateCustomerEmailMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

// The request seems to work but the update does not happen
export default async (
  customerData: UpdateCustomerEmailVariables,
  customerToken: string
): Promise<MagentoUpdateCustomerEmailResponse> => {
  const response = await axiosRequest<MagentoUpdateCustomerEmailResponse>(
    updateCustomerEmail(customerData),
    customerToken
  )
  if ('errors' in response && response.errors?.length) {
    printMagentoResponseErrors(response)
    throw new Error(response.errors[0].message)
  }
  return response
}
