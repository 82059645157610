import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: GetMagentoCarContentsVariables): GraphqlQuery => {
  const { cartId } = { ...variables }
  return {
    query: `
      query cart($cartId: String!) {
        cart(cart_id: $cartId) {
					${cartFragment}
        }
      }`,
    variables: {
      cartId,
    },
  }
}

export type GetMagentoCarContentsVariables = {
  cartId?: string | null
}

export type MagentoCartResponse = MagentoErrorResponse & {
  data?: {
    cart: MagentoCart
  }
}
