const moneyFragment = `
	value
	currency
`

export default moneyFragment

export type MagentoMoney = {
  value: number
  currency: string
}
