import cartFragment, { MagentoCart } from '../fragments/cartFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: SetGuestEmailOnCartVariables): GraphqlQuery => {
  const { cartId, email } = { ...variables }
  return {
    query: `
      mutation setGuestEmailOnCart(
        $cartId: String!,
        $email: String!,
        ) {
          setGuestEmailOnCart(
          input: {
            cart_id: $cartId
            email: $email
          }
        ) {
          cart {
            ${cartFragment}
          }
        }
      }`,
    variables: {
      cartId,
      email,
    },
  }
}

type SetGuestEmailOnCartVariables = {
  cartId: string
  email: string
}

export type MagentoSetGuestEmailOnCartResponse = MagentoErrorResponse & {
  data?: {
    setGuestEmailOnCart: {
      cart: MagentoCart
    }
  }
}
