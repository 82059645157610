import generateCustomerToken, {
  MagentoGenerateCustomerTokenResponse,
} from 'requests/graphql/mutations/generateCustomerTokenMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  loginData: LoginData
): Promise<MagentoGenerateCustomerTokenResponse> => {
  const response = await axiosRequest<MagentoGenerateCustomerTokenResponse>(
    generateCustomerToken(loginData)
  )
  if ('errors' in response && response.errors?.length) {
    printMagentoResponseErrors(response)
    throw new Error(response.errors[0].message)
  }
  return response
}

export type LoginData = {
  email: string
  password: string
}
