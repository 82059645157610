import setBillingAddressOnCartMutation, {
  MagentoSetBillingAddressResponse,
  SetBillingAddressOnCartVariables,
} from 'requests/graphql/mutations/setBillingAddressOnCartMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerData: CustomerBillingData,
  cartId: string,
  customerToken?: string | null
): Promise<boolean> => {
  const variables = {
    cartId,
    firstName: customerData.firstName,
    lastName: customerData.lastName,
    company: customerData.company,
    street: customerData.street,
    city: customerData.city,
    postcode: customerData.postcode,
    telephone: customerData.telephone,
    countryId: customerData.countryId,
  }
  const response = await axiosRequest<MagentoSetBillingAddressResponse>(
    setBillingAddressOnCartMutation(variables),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed setting billing address to Magento')
  }
  return true
}

export type CustomerBillingData = Omit<
  SetBillingAddressOnCartVariables,
  'cartId'
>
