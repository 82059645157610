import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: ResetPasswordRequestVariables): GraphqlQuery => {
  const { email, resetPasswordToken, newPassword } = { ...variables }

  return {
    query: `
		mutation resetPassword(
			$email: String!,
			$resetPasswordToken: String!,
			$newPassword: String!
		){
			resetPassword(
				email: $email
				resetPasswordToken: $resetPasswordToken
				newPassword: $newPassword
			)
		}
		`,
    variables: {
      email,
      resetPasswordToken,
      newPassword,
    },
  }
}

export type MagentoResetPasswordResponse = MagentoErrorResponse & {
  data?: {
    resetPassword: true
  }
}

type ResetPasswordRequestVariables = {
  email: string
  resetPasswordToken: string
  newPassword: string
}
