import changeCustomerPasswordMutation, {
  MagentoChangeCustomerPasswordResponse,
} from 'requests/graphql/mutations/changeCustomerPasswordMutation'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  data: ChangeCustomerPasswordData,
  customerToken: string
): Promise<MagentoChangeCustomerPasswordResponse> => {
  const variables = {
    currentPassword: data.currentPassword,
    newPassword: data.newPassword,
  }
  const response = await axiosRequest<MagentoChangeCustomerPasswordResponse>(
    changeCustomerPasswordMutation(variables),
    customerToken
  )
  if ('errors' in response && response.errors?.length) {
    printMagentoResponseErrors(response)
    throw new Error(response.errors[0].message)
  }
  return response
}

export type ChangeCustomerPasswordData = {
  currentPassword: string
  newPassword: string
}
