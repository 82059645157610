exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/templates/Cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/CmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-create-customer-tsx": () => import("./../../../src/templates/CreateCustomer.tsx" /* webpackChunkName: "component---src-templates-create-customer-tsx" */),
  "component---src-templates-forgotten-password-tsx": () => import("./../../../src/templates/ForgottenPassword.tsx" /* webpackChunkName: "component---src-templates-forgotten-password-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/Login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-order-success-tsx": () => import("./../../../src/templates/OrderSuccess.tsx" /* webpackChunkName: "component---src-templates-order-success-tsx" */),
  "component---src-templates-product-gift-card-tsx": () => import("./../../../src/templates/ProductGiftCard.tsx" /* webpackChunkName: "component---src-templates-product-gift-card-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/Profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-set-new-password-tsx": () => import("./../../../src/templates/SetNewPassword.tsx" /* webpackChunkName: "component---src-templates-set-new-password-tsx" */)
}

