import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: UpdateCustomerEmailVariables): GraphqlQuery => {
  const { email, password } = variables
  return {
    query: `
		mutation updateCustomerEmail(
			$email: String!,
			$password: String!,
		) {
			updateCustomerEmail(email: $email, password: $password) {
				customer {
					email
				}
			}
		}
		`,
    variables: {
      email,
      password,
    },
  }
}

export type UpdateCustomerEmailVariables = MagentoErrorResponse & {
  email: string
  password: string
}

export type MagentoUpdateCustomerEmailResponse = MagentoErrorResponse & {
  data?: {
    updateCustomerEmail: {
      customer: {
        email: string
      }
    }
  }
}
