import assignCustomerToGuestCart, {
  MagentoAssignCustomerToGuestCartResponse,
} from 'requests/graphql/mutations/assignCustomerToGuestCartMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  cartId: string,
  customerToken?: string | null
): Promise<MagentoAssignCustomerToGuestCartResponse> => {
  const response = await axiosRequest<MagentoAssignCustomerToGuestCartResponse>(
    assignCustomerToGuestCart({
      cartId,
    }),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed assigning customer to guest cart')
  }
  return response
}
