import sendMehiPasswordResetEmail, {
  SendMehiPasswordResetEmailResponse,
} from 'requests/graphql/mutations/sendMehiPasswordResetEmailMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'

export default async (
  email: string
): Promise<SendMehiPasswordResetEmailResponse> => {
  const response = await axiosRequest<SendMehiPasswordResetEmailResponse>(
    sendMehiPasswordResetEmail({ email })
  )
  return response
}
