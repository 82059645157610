import {
  MagentoCustomerOrder,
  MagentoCustomerOrdersList,
} from 'requests/graphql/fragments/customerOrdersFragment'

import { MagentoCustomer } from 'requests/graphql/fragments/customerFragment'
import { MagentoCustomerAddress } from 'requests/graphql/fragments/customerAddressFragment'

type EmptyCustomerAddress = {
  id: 0
  firstname: ''
  lastname: ''
  street: ['']
  postcode: ''
  city: ''
  region: {
    region_code: ''
    region: ''
  }
  country_code: ''
  telephone: ''
}
export const emptyCustomerAddress: EmptyCustomerAddress = {
  id: 0,
  firstname: '',
  lastname: '',
  street: [''],
  postcode: '',
  city: '',
  region: {
    region_code: '',
    region: '',
  },
  country_code: '',
  telephone: '',
}

type EmptyCustomerOrders = {
  total_count: 0
  items: []
}
export const emptyCustomerOrders: EmptyCustomerOrders = {
  total_count: 0,
  items: [],
}

type EmptyCustomerInfo = {
  firstname: ''
  lastname: ''
  email: ''
  is_subscribed: false
  addresses: []
  orders: EmptyCustomerOrders
}

export const emptyCustomerInfo: EmptyCustomerInfo = {
  firstname: '',
  lastname: '',
  email: '',
  is_subscribed: false,
  addresses: [],
  orders: emptyCustomerOrders,
}

const stateAddress = <T>(
  state: Record<string, unknown>,
  field: keyof MagentoCustomerAddress
): T | undefined => {
  if ('addresses' in state && Array.isArray(state.addresses)) {
    // We allow only one address
    const address = state.addresses[0]
    if (typeof address !== 'undefined') {
      if (field in address) {
        return address[field]
      }
    }
  }
  return undefined
}

export default (
  state: CustomerInfoData,
  action: CustomerInfoAction
): CustomerInfoData => {
  if (typeof state === 'object') {
    switch (action.type) {
      case 'firstname':
        if (typeof action.payload === 'string') {
          return { ...state, firstname: action.payload }
        }
        break
      case 'lastname':
        if (typeof action.payload === 'string') {
          return { ...state, lastname: action.payload }
        }
        break
      case 'email':
        if (typeof action.payload === 'string') {
          return { ...state, email: action.payload }
        }
        break
      case 'is_subscribed':
        if (typeof action.payload === 'boolean') {
          return { ...state, is_subscribed: action.payload }
        }
        break
      case 'addresses':
        // We allow only one address
        if (
          action.payload !== undefined &&
          typeof action.payload !== 'string' &&
          typeof action.payload !== 'boolean' &&
          'id' in action.payload
        ) {
          return {
            ...state,
            addresses: [
              {
                id: action.payload.id,
                firstname:
                  action.payload.firstname ||
                  stateAddress<string>(state, 'firstname'),
                lastname:
                  action.payload.lastname ||
                  stateAddress<string>(state, 'lastname'),
                street:
                  action.payload.street ||
                  stateAddress<string[]>(state, 'street'),
                postcode:
                  action.payload.postcode ||
                  stateAddress<string>(state, 'postcode'),
                city: action.payload.city || stateAddress(state, 'city'),
                region: action.payload.region || stateAddress(state, 'region'),
                country_code:
                  action.payload.country_code ||
                  stateAddress(state, 'country_code'),
                telephone:
                  action.payload.telephone || stateAddress(state, 'telephone'),
              },
            ],
          }
        }
        return state
      case 'orders':
        if (typeof action.payload === 'object' && 'items' in action.payload) {
          return {
            ...state,
            orders: action.payload,
          }
        }
        break
      case 'RESET':
        return emptyCustomerInfo
      default:
        return state
    }
  }
  return state
}

type CustomerInfoAction = {
  payload: ActionPayload
  type: CustomerInfoActionType
}

export type ActionPayload =
  | string
  | boolean
  | MagentoCustomerAddress
  | MagentoCustomerOrdersList

export type CustomerInfoActionType = keyof typeof emptyCustomerInfo | 'RESET'

export type CustomerInfoData = MagentoCustomer

export type CustomerOrdersList = MagentoCustomerOrdersList
export type StateCustomerOrder = MagentoCustomerOrder
