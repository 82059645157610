import { NotificationMessage } from 'components/Notification'

export const initialNotificationState: NotificationMessage = {
  type: undefined,
  message: undefined,
}

export default (state: NotificationMessage, action: Action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION':
      return {
        type: action.payload.type,
        message: action.payload.message,
      }
    case 'REMOVE_NOTIFICAITON':
      return {
        type: undefined,
        message: undefined,
      }
    default:
      return state
  }
}

type Action = {
  type: 'SET_NOTIFICATION' | 'REMOVE_NOTIFICAITON'
  payload: NotificationMessage
}
