import styled, { createGlobalStyle } from 'styled-components'

import { BreadCrumbs } from 'components/BreadCrumbs'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import React from 'react'
import State from 'state/State'
import { reset } from '@mehilainen/design-system'
import styleTokens from 'styleTokens'

const GlobalStyle = createGlobalStyle`
  ${reset};
	// TODO: set and use tokens for font sizes
	.fonts-loaded & {
      font-family: "Gotham SSm A", "Gotham SSm B", Arial, Helvetica, sans-serif;
      letter-spacing: 0;
    }
  body {
		--content-container-max-width: ${styleTokens['content-container-max-width']};
		--content-container-gap: ${styleTokens['content-container-gap']};
		--content-container-inline-padding: ${
      styleTokens['content-container-inline-padding']
    };
		overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: black;
    :hover {
      color: ${props => props.theme.primaryGreen};
      transition: color 0.2s ease;
    }
		&[href^="http"]::after, &[href^="https://"]::after {
			content: "";
			width: 11px;
			height: 11px;
			margin-left: 4px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			margin-bottom: 0.5ex; 
		}
  }
	a.apply-link-style {
		color: ${props => props.theme.primaryGreen};
		text-decoration: underline;
	}

  h1 {
    font-weight: 400 !important;
    font-size: 37px;
    line-height: 51.8px !important;
  }

  h2 {
    font-weight: 400 !important;
    font-size: 31px;
    margin: 16px 0;
    line-height: 43.4px !important;
  }

	h3 {
		font-weight: 400;
		font-size: 21px;
	}

	p {
		font-weight: 325;
	}
	/* Scroll margin allowance above anchor links */
	:target {
		scroll-margin-top: 2rem;
	}

	/* Scroll margin allowance below focused elements 
	to ensure they are clearly in view */
	:focus {
		scroll-margin-bottom: 2rem;
	}
	// Focus style for button role
  button:focus, [role='button']:focus {
    outline: 2px solid orange !important;
  }
	.visually-hidden {
		position: absolute !important;
		clip: rect(1px, 1px, 1px, 1px) !important;
		padding:0 !important;
		border:0 !important;
		height: 1px !important;
		width: 1px !important;
		overflow: hidden !important;
	}
	
  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    h1 {
      font-size: 27px !important;
    }

    h2 {
      font-size: 22px !important;
    }
  }
`

interface PageData {
  element: React.ReactElement
}

// Redirect Firebase default domains to proper domain.
if (typeof window !== 'undefined') {
  if (
    window.location.hostname === 'verkkokauppa-front-dev.web.app' ||
    window.location.hostname === 'verkkokauppa-front-dev.firebaseapp.com' ||
    window.location.hostname === 'mehi-verkkokauppa-front-dev.web.app' ||
    window.location.hostname === 'mehi-verkkokauppa-front-dev.firebaseapp.com'
  ) {
    window.location.href = 'https://verkkokauppa-front-dev.www.tunkkaajat.fi/'
  }
  if (
    window.location.hostname === 'verkkokauppa-front-prod.web.app' ||
    window.location.hostname === 'verkkokauppa-front-prod.firebaseapp.com' ||
    window.location.hostname === 'mehi-verkkokauppa-front-prod.web.app' ||
    window.location.hostname === 'mehi-verkkokauppa-front-prod.firebaseapp.com'
  ) {
    window.location.href = 'https://verkkokauppa.mehilainen.fi/'
  }
}

export default ({ element }: PageData) => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <State>
          {/* Disabled for now. */}
          {/* <TopBanner /> */}
          <Navigation />
          <BreadCrumbs
            path={
              typeof window !== 'undefined' ? window.location.pathname : '/'
            }
          />
          {element}
          <Footer />
        </State>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: var(--content-container-max-width);
  margin: auto;
  footer {
    margin-block-start: auto;
  }
`
