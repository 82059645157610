import { MagentoErrorResponse } from 'utils/errorHandling'

export default (
  variables: SendMehiPasswordResetEmailRequestVariables
): GraphqlQuery => {
  const { email } = { ...variables }

  return {
    query: `
		mutation sendMehiPasswordResetEmail(
			$email: String!	
		){
			sendMehiPasswordResetEmail(
					input:{
							email: $email     
					}
			){
					result
					message
			}
		}
		`,
    variables: {
      email,
    },
  }
}

export type SendMehiPasswordResetEmailResponse = MagentoErrorResponse & {
  data?: {
    sendMehiPasswordResetEmail: {
      message: string
      result: boolean
    }
  }
}

type SendMehiPasswordResetEmailRequestVariables = {
  email: string
}
