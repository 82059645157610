import customerAddressFragment, {
  MagentoCustomerAddress,
} from './customerAddressFragment'
import customerOrdersFragment, {
  MagentoCustomerOrdersList,
} from './customerOrdersFragment'

const customerFragment = `
	firstname
	lastname
	email
	is_subscribed
	addresses {
		${customerAddressFragment}
	}
	orders {
		${customerOrdersFragment}
	}
`

export default customerFragment

export type MagentoCustomer = {
  firstname?: string
  lastname?: string
  email?: string
  is_subscribed?: boolean
  addresses?: MagentoCustomerAddress[] | []
  orders?: MagentoCustomerOrdersList
}
