import { MagentoErrorResponse } from 'utils/errorHandling'

export default (): GraphqlQuery => {
  return {
    query: `
		mutation {
			revokeCustomerToken {
				result
			}
		}
		`,
  }
}

export type MagentoRevokeCustomerTokenResponse = MagentoErrorResponse & {
  data?: {
    revokeCustomerToken: {
      result: boolean
    }
  }
}
