import mergeCarts, {
  MagentoMergeCartsResponse,
} from 'requests/graphql/mutations/mergeCartsMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  guestCartId: string,
  customerCartId: string,
  customerToken?: string | null
): Promise<MagentoMergeCartsResponse> => {
  const response = await axiosRequest<MagentoMergeCartsResponse>(
    mergeCarts({
      sourceCartId: guestCartId,
      destinationCartId: customerCartId,
    }),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed merging guest cart to customer cart')
  }
  return response
}
