import { FiLogOut } from 'react-icons/fi'
import { Link } from 'gatsby'
import React from 'react'
import ScreenReaderOnly from './ScreenReaderOnly'
import styled from 'styled-components'

type Props = {
  handleLogout: () => void
}

export default ({ handleLogout }: Props) => {
  return (
    <Link to={'/'} aria-labelledby="logout" onClick={handleLogout}>
      <ScreenReaderOnly>Logout</ScreenReaderOnly>
      <LogoContainer>
        <FiLogOut />
      </LogoContainer>
    </Link>
  )
}

const LogoContainer = styled.div`
  svg {
    transform: scale(1.7);
  }
`
