import { MagentoCustomer } from '../fragments/customerFragment'
import { MagentoErrorResponse } from 'utils/errorHandling'
import customerAddressFragment from '../fragments/customerAddressFragment'

export default (variables: CreateCustomerRequestVariables): GraphqlQuery => {
  const { firstname, lastname, email, password, is_subscribed } = {
    ...variables,
  }
  return {
    query: `
      mutation createCustomerV2(
        $firstname: String!,
        $lastname: String!,
        $email: String!,
        $password: String!,
				$is_subscribed: Boolean!
        ) {
          createCustomerV2(
          input: {
            firstname: $firstname
            lastname: $lastname
            email: $email
            password: $password
						is_subscribed: $is_subscribed
          }
        ) {
          customer {
						firstname
						lastname
						email
						is_subscribed
						addresses {
							${customerAddressFragment}
						}
					}
        }
      }`,
    variables: {
      firstname,
      lastname,
      email,
      password,
      is_subscribed,
    },
  }
}

export type MagentoCrateCustomerResponse = MagentoErrorResponse & {
  data?: {
    createCustomer: MagentoCustomer
  }
}

export type CreateCustomerRequestVariables = {
  firstname: string
  lastname: string
  email: string
  password: string
  is_subscribed: boolean
}
