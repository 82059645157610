import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: IsEmailAvailableVariables): GraphqlQuery => {
  const { email } = { ...variables }
  return {
    query: `
      query isEmailAvailable(
        $email: String!,
        ) {
          isEmailAvailable(email: $email) {
            is_email_available
          }
        }`,
    variables: {
      email,
    },
  }
}

type IsEmailAvailableVariables = {
  email: string
}

export type MagentoIsEmailAvailableResponse = MagentoErrorResponse & {
  data: {
    isEmailAvailable: {
      is_email_available: boolean
    }
  }
}
