import createCustomerAddress, {
  CreateCustomerAddressVariables,
  MagentoCreateCustomerAddressResponse,
} from 'requests/graphql/mutations/createCustomerAddressMutation'

import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerData: CreateCustomerAddressVariables,
  customerToken: string
): Promise<MagentoCreateCustomerAddressResponse> => {
  const response = await axiosRequest<MagentoCreateCustomerAddressResponse>(
    createCustomerAddress(customerData),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed creating customer address')
  }
  return response
}
