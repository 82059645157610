import addMpGiftCardProductsToCartMutation, {
  MagentoAddGiftcardProductToCartResponse,
} from 'requests/graphql/mutations/addMpGiftCardProductsToCartMutation'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  cartId: string,
  sku: string,
  data: GiftcardData,
  customerToken?: string | null
): Promise<MagentoAddGiftcardProductToCartResponse> => {
  const { receiverEmail, sum, message } = { ...data }
  const variables = {
    sku: sku,
    sum,
    email: receiverEmail ?? 'admin@example.com', //BE gets this from payment data.
    message: message ?? '',
    cartId,
    isCustomSum: sku === 'lahjakortti',
  }
  const response = await axiosRequest<MagentoAddGiftcardProductToCartResponse>(
    addMpGiftCardProductsToCartMutation(variables),
    customerToken
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed adding product to cart')
  }
  return response
}

export type GiftcardData = {
  sum: number
  receiverName?: string
  receiverEmail?: string
  message?: string
}
