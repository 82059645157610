import * as cartService from 'requests/cartRequests'

import React, { createContext, useReducer } from 'react'
import cartReducer, {
  CartContent,
  emptyCartContent,
  initialCartState,
} from '../reducers/cartReducer'

import { CustomerBillingData } from 'requests/magento_cart/setBillingAddressToMagento'
import { GiftcardData } from 'requests/magento_cart/addGiftcardToMagentoCart'
import { MagentoCart } from 'requests/graphql/fragments/cartFragment'
import { MagentoSetPaymentMethodOnCartResponse } from 'requests/graphql/mutations/setPaymentMethodOnCartMutation'

// Initialize the context with an empty object that respects the types
export const CartContext = createContext<CartProvider>({
  addGiftCardToCart: () => {
    return
  },
  assignCustomerToCart: () => Promise.resolve(false),
  cartContent: emptyCartContent,
  cartId: initialCartState.cartId,
  createCart: () => Promise.resolve(''),
  loadGuestCart: () => Promise.resolve(''),
  loadCustomerCart: () => Promise.resolve(''),
  mergeGuestCartToCustomerCart: () => Promise.resolve(false),
  payOrder: () => Promise.resolve(false),
  removeItem: () => Promise.resolve({} as MagentoCart),
  resetCustomerCart: () => Promise.resolve(''),
  resetGuestCart: () => Promise.resolve(''),
  resetLocalCart: () => false,
  setCartId: () => {
    return
  },
  total_quantity: 0,
})

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default ({ children }: Props) => {
  const [state, dispatch] = useReducer(cartReducer, initialCartState)

  const addGiftCardToCart = async (
    sku: string,
    data: GiftcardData,
    customerToken?: string | null
  ): Promise<string> => {
    if (state.cartId) {
      const response = await cartService.addGiftcardToMagentoCart(
        state.cartId,
        sku,
        data,
        customerToken
      )
      const cart = response.data?.addMpGiftCardProductsToCart?.cart
      if (cart) {
        const { id, ...cartContent } = cart
        dispatch({ type: 'SET_ALL_CONTENT', payload: cartContent })
        return id
      }
      throw new Error(
        'Failed adding giftcard to cart. Cart missing from Magento response'
      )
    }
    throw new Error('Failed adding giftcard to cart. Cart id is missing')
  }

  const assignCustomerToCart = async (customerToken: string) => {
    if (state.cartId) {
      await cartService.assignCustomerToCart(state.cartId, customerToken)
      return Promise.resolve(true)
    } else {
      throw new Error('No cartId present in state')
    }
  }

  const cartContent = (): CartContent => {
    return state.cartContent as CartContent
  }

  const createCart = async (customerToken?: string | null): Promise<string> => {
    resetLocalCart()
    const response = await cartService.createMagentoCart(customerToken)
    if (response.data) {
      const newCartId = response.data.createEmptyCart
      setCartId(newCartId)
      return newCartId
    } else {
      console.error(
        'Failed creating empty cart, no data field in response object',
        response
      )
      throw new Error(
        'Failed crering empty cart, no data field in response object'
      )
    }
  }

  const loadGuestCart = async (cartId: string): Promise<string> => {
    const response = await cartService.getCart(cartId)
    const cartData = response.data
    if (cartData) {
      const cart = cartData.cart
      const { id, ...cartContent } = cart
      setCartId(id)
      dispatch({ type: 'SET_ALL_CONTENT', payload: cartContent })
      return cart.id
    } else {
      console.error(
        'Failed getting cart, no data field in response object',
        response
      )
      throw new Error('Failed getting cart, no data field in response object')
    }
  }

  const loadCustomerCart = async (customerToken: string): Promise<string> => {
    const response = await cartService.getCustomerCart(customerToken)
    const cartData = response.data
    if (cartData) {
      const customerCart = cartData.customerCart
      const { id, ...cartContent } = customerCart
      setCartId(id)
      dispatch({ type: 'SET_ALL_CONTENT', payload: cartContent })
      return customerCart.id
    } else {
      console.error(
        'Failed getting cart, no data field in response object',
        response
      )
      throw new Error('Failed getting cart, no data field in response object')
    }
  }

  const mergeGuestCartToCustomerCart = async (
    guestCartId: string,
    customerCartId: string,
    customerToken: string
  ): Promise<boolean> => {
    const response = await cartService.mergeCarts(
      guestCartId,
      customerCartId,
      customerToken
    )
    const mergedCarts = response.data?.mergeCarts
    if (mergedCarts) {
      dispatch({ type: 'SET_ALL_CONTENT', payload: mergedCarts })
      return true
    } else {
      throw new Error('Failed merging guest and customer carts')
    }
  }

  const payOrder = async (
    customerData: CustomerPaymentData,
    customerToken?: string | null
  ): Promise<boolean> => {
    if (!state.cartId) {
      throw new Error('Failed initializing order payment, cartId is missing')
    }
    const response = await sendOrder(customerData, state.cartId, customerToken)
    return response && true
  }

  const removeItem = async (
    itemId: number,
    customerToken?: string | null
  ): Promise<MagentoCart> => {
    if (!state.cartId) {
      throw new Error('Can not remove item, cartId is missing')
    }
    const response = await cartService.removeItemFromCart(
      state.cartId,
      itemId,
      customerToken
    )
    const cart = response.data?.removeItemFromCart.cart
    if (cart) {
      return cart
    } else {
      throw new Error(
        'Failed resetting cart after removing item. No cart returned'
      )
    }
  }

  const resetCustomerCart = async (customerToken: string): Promise<string> => {
    dispatch({ type: 'RESET_CONTENT', payload: '' })
    const content = cartContent()
    if (content.items) {
      const updatedCartsPromises = content.items.map(
        async item => await removeItem(item.id, customerToken)
      )
      await Promise.all(updatedCartsPromises)
    }
    const cartId = await loadCustomerCart(customerToken)
    return cartId
  }

  const resetGuestCart = async (): Promise<string> => {
    dispatch({ type: 'RESET_CONTENT', payload: '' })
    const content = cartContent()
    if (content.items) {
      const updatedCartsPromises = content.items.map(
        async item => await removeItem(item.id)
      )
      await Promise.all(updatedCartsPromises)
    }
    if (state.cartId) {
      const cartId = await loadGuestCart(state.cartId as string)
      return cartId
    }
    throw new Error('Cart id missing from state')
  }

  const resetLocalCart = (): boolean => {
    dispatch({ type: 'RESET_CONTENT', payload: '' })
    return true
  }

  const sendOrder = async (
    customerData: CustomerPaymentData,
    cartId: string,
    customerToken?: string | null
  ): Promise<MagentoSetPaymentMethodOnCartResponse> => {
    const setBillingSuccess = await cartService.setBillingAddressToMagento(
      customerData,
      cartId,
      customerToken
    )
    const setEmailSuccess =
      customerToken ||
      (await cartService.setGuestEmailOnCart(customerData.email, cartId))
    if (setBillingSuccess && setEmailSuccess) {
      //Set paytrail as payment method
      return await cartService.setPaymentMethodOnCart(cartId, customerToken)
    } else {
      throw new Error('Failed sending order to Magento')
    }
  }

  const setCartId = (cartId: string | null) => {
    dispatch({ type: 'SET_CART_ID', payload: cartId })
  }

  const total_quantity = (): number => {
    return state.cartContent && 'total_quantity' in state.cartContent
      ? state.cartContent.total_quantity ?? 0
      : 0
  }

  const value: CartProvider = {
    addGiftCardToCart,
    assignCustomerToCart: assignCustomerToCart,
    cartContent: cartContent(),
    cartId: state.cartId,
    createCart,
    loadGuestCart,
    loadCustomerCart,
    mergeGuestCartToCustomerCart,
    payOrder,
    removeItem,
    resetCustomerCart,
    resetGuestCart,
    resetLocalCart,
    setCartId,
    total_quantity: total_quantity(),
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

type CartProvider = {
  addGiftCardToCart: (
    sku: string,
    data: GiftcardData,
    customerToken?: string | null
  ) => void
  assignCustomerToCart: (customerToken: string) => Promise<boolean>
  cartContent: CartContent
  cartId?: string | null
  createCart: (customerToken?: string | null) => Promise<string>
  loadGuestCart: (cartId: string) => Promise<string>
  loadCustomerCart: (customerToken: string) => Promise<string>
  mergeGuestCartToCustomerCart: (
    guestCartId: string,
    customerCartId: string,
    customerToken: string
  ) => Promise<boolean>
  payOrder: (
    customerData: CustomerPaymentData,
    customerToken?: string | null
  ) => Promise<boolean>
  removeItem: (itemId: number) => Promise<MagentoCart>
  resetCustomerCart: (customerToken: string) => Promise<string>
  resetGuestCart: () => Promise<string>
  resetLocalCart: () => boolean
  setCartId: (cartId: string | null) => void
  total_quantity: number
}

type CustomerPaymentData = CustomerBillingData & { email: string }
