import createEmptyCartMutation, {
  MagentoNewCartResponse,
} from 'requests/graphql/mutations/createEmptyCartMutation'
import { axiosRequest } from 'requests/axios/axiosRequests'
import { printMagentoResponseErrors } from 'utils/errorHandling'

export default async (
  customerToken?: string | null
): Promise<MagentoNewCartResponse> => {
  const response = await axiosRequest<MagentoNewCartResponse>(
    createEmptyCartMutation(),
    customerToken || undefined
  )
  if ('errors' in response) {
    printMagentoResponseErrors(response)
    throw new Error('Failed creating new cart')
  }
  return response
}
