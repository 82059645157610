import { MagentoCustomerOrderItem } from 'requests/graphql/fragments/customerOrdersFragment'
import { StateCustomerOrder } from 'state/reducers/customerReducer'

export function addToCartGA({ item, price, index, giftCardSku }: ItemData) {
  initializeGADataLayerEcommerce()
  pushItemToDataLayer({ item, price, index, giftCardSku })('add_to_cart')
}

export function addPaymentInfoGA(
  itemsList: Product[],
  giftCardSku: string,
  paymentType: string
) {
  initializeGADataLayer()
  pushListToDataLayer(itemsList, giftCardSku)('add_payment_info', {
    payment_type: paymentType,
  })
}

export function beginCheckoutGA(itemsList: Product[], giftCardSku: string) {
  initializeGADataLayerEcommerce()
  pushListToDataLayer(itemsList, giftCardSku)('begin_checkout')
}

export function emptyAllFromCartGA(items: Product[], giftCardSku: string) {
  initializeGADataLayerEcommerce()
  pushListToDataLayer(items, giftCardSku)('remove_from_cart')
}

export function loginGA() {
  initializeGADataLayer()
  pushToDataLayer({ event: 'login' })
}

export function purchaseAnonymousGA(
  order: { id: string; number: number; order_date: string },
  giftCardSku: string
) {
  initializeGADataLayer()
  const items = [].map(item => makeProductFromOrderItem(item))
  pushListToDataLayer(items, giftCardSku)('purchase', {
    currency: 'EUR',
    value: undefined,
    tax: 0,
    transaction_id: order.id,
  })
}

export function purchaseGA(order: StateCustomerOrder, giftCardSku: string) {
  initializeGADataLayer()
  const items = order.items.map(item => makeProductFromOrderItem(item))
  pushListToDataLayer(items, giftCardSku)('purchase', {
    currency: 'EUR',
    value: order.total.grand_total.value,
    tax: 0,
    transaction_id: order.number.toString(),
  })
}

export function registrationGA() {
  initializeGADataLayer()
  pushToDataLayer({ event: 'registration' })
}

export function removeItemFromCartGA({
  item,
  price,
  index,
  giftCardSku,
}: ItemData) {
  initializeGADataLayerEcommerce()
  pushItemToDataLayer({ item, price, index, giftCardSku })('remove_from_cart')
}

export function viewItemListGA(itemsList: Product[], giftCardSku: string) {
  initializeGADataLayerEcommerce()
  pushListToDataLayer(itemsList, giftCardSku)('view_item_list')
}

export function selectItemGA({ item, price, index, giftCardSku }: ItemData) {
  initializeGADataLayerEcommerce()
  pushItemToDataLayer({ item, price, index, giftCardSku })('select_item')
}

export function userIsLoggedInGA() {
  initializeGADataLayer()
  pushToDataLayer({ event: 'user_is_logged_in' })
}

export function viewItemGA({ item, price, index, giftCardSku }: ItemData) {
  initializeGADataLayerEcommerce()
  pushItemToDataLayer({ item, price, index, giftCardSku })('view_item')
}

export function viewCartGA(
  itemsList: (Product & Required<Quantity>)[],
  giftCardSku: string
) {
  initializeGADataLayerEcommerce()
  pushListToDataLayer(itemsList, giftCardSku)('view_cart')
}

const checkWindowExists = () => {
  if (typeof window === undefined) {
    throw new Error(
      'To push items to the data layer call this function withing a browser'
    )
  }
}

function initializeGADataLayer() {
  checkWindowExists()
  if (!window.dataLayer) {
    window.dataLayer = []
  }
}

function initializeGADataLayerEcommerce() {
  initializeGADataLayer()
  window.dataLayer.push({ ecommerce: null })
}

const itemListName = (item: Product, giftCardSku?: string): string => {
  return !giftCardSku || item.sku !== giftCardSku
    ? 'Tarkastukset'
    : 'Lahjakortti'
}

const getItemVariant = (
  item: Product,
  price?: number,
  giftCardSku?: string
): string | undefined => {
  const variant = price ? price.toString() + ' €' : undefined
  return !giftCardSku || item.sku !== giftCardSku ? undefined : variant
}

const makeDataLayerObject = (
  eventName?: Gtag.EventNames,
  ecommerce?: Gtag.Ecommerce | null
): Gtag.DataLayerObject => {
  return {
    event: eventName,
    ecommerce,
  }
}

const makeEcommerceItem = ({
  item,
  price,
  item_list_name,
  item_variant,
  index,
}: {
  item: ProductWithQuantity
  price?: number
  item_list_name?: string
  item_variant?: string
  index?: number
}): Gtag.Item => {
  const categories = item.categories ?? []
  return {
    item_name: item.name,
    item_id: item.sku,
    currency: 'EUR',
    price,
    item_brand: item.brand,
    item_category: categories[0]?.name,
    item_category2: categories[1]?.name,
    quantity: item.quantity,
    item_list_name,
    item_variant,
    index,
  }
}

const makeEcommerceObject = (
  items: Gtag.Item | Gtag.Item[],
  keyValues?: Omit<Gtag.Ecommerce, 'items'>
): Gtag.Ecommerce => {
  return {
    items: Array.isArray(items) ? items : [items],
    ...keyValues,
  }
}

const makeProductFromOrderItem = (
  orderItem: MagentoCustomerOrderItem
): ProductWithQuantity => {
  return {
    gift_card_amounts: JSON.stringify([
      {
        amount: orderItem.product_sale_price.value,
      },
    ]),
    name: orderItem.product_name,
    price_range: {
      minimum_price: {
        regular_price: {
          value: orderItem.product_sale_price.value,
        },
      },
    },
    quantity: orderItem.quantity_ordered,
    sku: orderItem.product_sku,
    url_key: orderItem.product_url_key,
  }
}

const priceFromProduct = (product: Product): number | undefined => {
  const prices = JSON.parse(product.gift_card_amounts ?? [])
  // If more than one price, then it is a gift cart where the price has not been given yet
  return prices.length === 1 ? prices[0]?.amount : undefined
}

// function incrementItemAmountInDataLayer(product: Product): boolean {
//   const indexInDataLayer = productIndexInDataLayer(product)
//   const item =
//     indexInDataLayer >= 0 &&
//     window.dataLayer[indexInDataLayer].ecommerce?.items.find(
//       (item: Gtag.Item) => item.item_id === product.sku
//     )
//   if (item) {
//     const oldQuantity = item.quantity ?? 0
//     item.quantity = oldQuantity + 1
//     return true
//   }
//   return false
// }

// const productIndexInDataLayer = (product: Product): number => {
//   return window.dataLayer.findIndex((dLObj: Gtag.DataLayerObject) => {
//     return dLObj.ecommerce?.items.find(
//       (item: Gtag.Item) => item.item_id === product.sku
//     )
//   })
// }

function pushItemToDataLayer({
  item,
  price,
  index,
  giftCardSku,
}: ItemData): (eventName: Gtag.EventNames) => void {
  checkWindowExists()
  const ecommerceItem = makeEcommerceItem({
    item,
    price,
    item_list_name: itemListName(item, giftCardSku),
    item_variant: getItemVariant(item, price, giftCardSku),
    index,
  })
  const ecommerceObject = makeEcommerceObject(ecommerceItem)
  return (eventName: Gtag.EventNames) =>
    pushToDataLayer(makeDataLayerObject(eventName, ecommerceObject))
}

function pushListToDataLayer(
  itemsList: ProductWithQuantity[],
  giftCardSku: string
): (
  eventName: Gtag.EventNames,
  keyValues?: Omit<Gtag.Ecommerce, 'items'>
) => void {
  checkWindowExists()
  const ecommerceItems = itemsList.map((item, index) => {
    const price = priceFromProduct(item)
    return makeEcommerceItem({
      item,
      price,
      item_list_name: itemListName(item, giftCardSku),
      item_variant: getItemVariant(item, price, giftCardSku),
      index,
    })
  })
  return (
    eventName?: Gtag.EventNames,
    keyValues?: Omit<Gtag.Ecommerce, 'items'>
  ) => {
    const ecommerce = makeEcommerceObject(ecommerceItems, keyValues)
    pushToDataLayer(makeDataLayerObject(eventName, ecommerce))
  }
}

function pushToDataLayer(data: Gtag.DataLayerObject) {
  window.dataLayer?.push(data)
}

type ItemData = {
  item: ProductWithQuantity
  price?: number
  index?: number
  giftCardSku?: string
}

type ProductWithQuantity = Product & Partial<Quantity>
