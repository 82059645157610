import customerFragment, {
  MagentoCustomer,
} from '../fragments/customerFragment'

import { MagentoErrorResponse } from 'utils/errorHandling'

export default (variables: UpdateCustomerV2Variables): GraphqlQuery => {
  const { firstname, lastname } = variables

  return {
    query: `
			mutation updateCustomerV2(
				$firstname: String!
				$lastname: String!
			) {
				updateCustomerV2(
					input: {
						firstname: $firstname
						lastname: $lastname
					}
				) {
					customer {
						${customerFragment}
					}
				}
			}
		`,
    variables: {
      firstname,
      lastname,
    },
  }
}

type UpdateCustomerV2Variables = MagentoErrorResponse & {
  firstname: string
  lastname: string
}

export type MagentoUpdateCustomerV2Response = MagentoErrorResponse & {
  data?: {
    updateCustomerV2: {
      customer: MagentoCustomer
    }
  }
}
